<template>
  <VContent>
    <template slot="right">
      <h6>
        {{ title }} >
        <span class="text-muted">{{ type ? "الشركات" : "الأفراد" }}</span>
      </h6>
    </template>
    <template slot="left">
      <b-button size="sm" variant="success" class="ml-3" @click="update()"
        >تحديث</b-button
      >
    </template>
    <template slot="content">
      <b-tabs content-class="mt-3" v-model="type">
        <b-tab title="الأفراد">
          <VTextEditor v-model="form.individual" class="mb-5"></VTextEditor>
        </b-tab>
        <b-tab title="الشركات">
          <VTextEditor v-model="form.companies"></VTextEditor>
        </b-tab>
      </b-tabs>
    </template>
  </VContent>
</template>

<script>
import { clearObject, successAlert, errorAlert } from "@/utils/helper";

export default {
  data() {
    return {
      id: null,
      type: 0,
      title: null,
    };
  },
  computed: {
    form: {
      get() {
        return this.$store.state.banks.accounts.form;
      },
      set(value) {
        this.$store.commit("banks/accounts/SET_ACCOUNT", value);
      },
    },
  },
  watch: {
    $route() {
      this.get();
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.id = this.$route.meta.id;
      this.title = this.$route.name;
      this.$store.dispatch("banks/accounts/get", this.id);
    },
    update() {
      this.$store
        .dispatch("banks/accounts/update", this.id)
        .then((response) => {
          successAlert();
          this.$store.commit("banks/accounts/SET_ACCOUNT", response.payload);
        })
        .catch((error) =>
          errorAlert(
            error.response ? error.response.data.message : "حدثت مشكله"
          )
        );
    },
    clearForm() {
      this.form = clearObject(this.form);
      this.$emit("close");
    },
  },
};
</script>
<style scoped></style>
